<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">问卷统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">{{
            editJson.projectName
          }}</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{stu == 'all'? '期末问卷汇总': '每日问卷汇总'}}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <div class="searchbox changetreestyle">
            <!-- <div class="flexcb">
              <el-button
                style="padding:8px 20px"
                class="bgc-bv"
                @click="goback()"
                >返回</el-button
              >
            </div> -->
            <div
              class="flexdc"
              style="align-items: flex-start; background:rgba(241, 247, 253, 1)"
            >
              <div style="padding:20px" class="rowJsontitle">
                <div>
                  <span>
                    班级名称：
                    <em style="color: #5c6be8;font-size:15px">{{
                      form.projectName
                    }}</em>
                  </span>
                </div>
                <div>
                  <span>
                    课程名称：
                    <em style="color: #5c6be8;font-size:15px">{{
                      form.courseName
                    }}</em>
                  </span>
                </div>
                <div>
                  <span>
                    问卷名称：
                    <em style="color: #5c6be8;font-size:15px">{{
                      form.paperName
                    }}</em>
                  </span>
                </div>
                <div>
                  <span>
                    答卷人数：
                    <em style="color: #5c6be8;font-size:15px">{{
                      form.answerNumber
                    }}人</em>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="framePage-scroll flexdc">
            <div
              class="bottom searchbox"
              style="padding-top:0; padding-right:1rem"
            >
              <h3>{{form.paperDescription}}</h3>
            </div>
            <div class="ovy-a flex1" id="list-box">
              <div
                class="listExam"
                v-for="(item, index) in form.questionList"
                :key="index"
              >
                <div class="list-item df">
                  <div class="subject flex1">
                    <div class="subject-title">
                      <span>{{ indexMethod(index) }}、</span>
                      <span>{{ item.questionName }}</span>
                    </div>
                    <div
                      class="option"
                      v-if="item.questionType != 30"
                      style="padding-left:15px"
                    >
                      <div
                        v-for="(item1, index) in item.optionList"
                        :key="index"
                        style="display:flex;align-items:center;padding:10px 0"
                      >
                        <span
                          >{{ optionFM(index) }}、{{ item1.optionName }}</span
                        >
                        <!-- <el-progress :percentage="50"  :stroke-width="20"></el-progress> -->
                        <span style="padding-left:10%">{{
                          item1.percentage
                        }}</span>
                      </div>
                    </div>
                    <div class="option" v-else style="padding:15px 0 0 15px">
                      <span style="font-size:1rem;">{{
                        item.answerContent
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "set/courseUser",
  components: {
    // Empty,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      selectData: {
        catalogName: "",
      },
      editJson: {},
      form: {},
    };
  },
  computed: {},
  created() {
  },
  methods: {
    init() {
      this.editJson = JSON.parse(this.$route.query.editInfo).row;
      this.stu = this.$route.query.stu;
      this.getTableHeight();
      this.getDataList()
    },
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      }
    },
    getDataList() {
      const params = {
        projectCourseId: this.editJson.projectCourseId,
        projectId: this.editJson.projectId,
        paperId: this.editJson.paperId,
      };
      if (this.editJson.kpointId) {
        params.kpointId = this.editJson.kpointId;
      }
      this.$post("/survey/sjzcx/summary", params)
        .then((ret) => {
          this.form = ret.data || {};
        })
        .catch((err) => {
          return;
        });
      // this.doFetch({
      //   url: "/survey/sjzcx/summary",
      //   params,
      //   pageNum
      // });
    },
    goback() {
      this.$router.push({
        path: "/web/set/questionnaireDailysummary",
        query: {
          liuliuliu: true,
          projectCourseId: this.editJson.projectCourseId,
          projectId: this.editJson.projectId,
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function() {},
};
</script>

<style lang="less" scope>
.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  > .cell {
    text-align: left;
  }
}
.el-table th.is-right {
  > .cell {
    text-align: right;
  }
}
.subject {
  padding-left: 15px;
  .subject-title {
    font-size: 1rem;
  }
}
.listExam {
  margin: 15px 0;
  background: #fafafa;
  min-height: 7.5rem;
  .list-item {
    padding-top: 0.5rem;
  }
}
.rowJsontitle {
  padding: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-around;
  > div {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
}
</style>
